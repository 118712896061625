// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

$nanowear-blue: #089bc1;
$nanowear-green: #44af69;
$nanowear-white: #edf2f4;
$nanowear-battleship: #2b2d42;
$nanowear-red: #8b575c;

html {
  background-color: $nanowear-battleship !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: $nanowear-white;
}

$family-sans-serif: 'Roboto', sans-serif;
$grey-dark: $nanowear-battleship;
$primary: $nanowear-blue;
$link: $nanowear-blue;
$widescreen-enabled: false;
$fullhd-enabled: false;
$danger: $nanowear-red;
$navbar-background-color: $nanowear-battleship;
$navbar-item-color: $nanowear-white;
$navbar-item-hover-color: $nanowear-white;
$navbar-item-hover-background-color: $nanowear-blue;
$table-cell-padding: 1em 1em;
$body-background-color: $nanowear-white;
$footer-background-color: $nanowear-battleship;

.footer {
  color: $nanowear-white;
}

@import '../node_modules/bulma/bulma';
@import '../node_modules/bulma-divider/dist/css/bulma-divider';
@import '../node_modules/bulma-tooltip/dist/css/bulma-tooltip.sass';

.Home {
  .Home-hero-image {
    background-image: url('/images/home-background-image.jpg') !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #999;
  }
}

.navbar-logo {
  margin-right: 10px;
}

.tag {
  width: 100%;
}

img.plot-image {
  width: 100%;
}

.Charts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chart-channels {
  /* todo: this is imperfect */
  /* we want the channels to fill horizontal width
     without occluding the right gutter */
  width: 95vw;
}

.charts-header {
  position: sticky;
  top: 0;
}
